$(function() {
    show_supporting_comment();

    function show_supporting_comment(){
    var code = location.pathname.split('/')[2];
    var url = '/api/com/supporter-slim/' + code;

    $.ajax({
        type: 'GET',
        url: url,
        cache: false,
        dataType : 'json',
    }).then(
        function (json) {
            if (!Array.isArray(json.threads) || json.threads.length == 0) {
                return;
            }
            $('#supporting-comment').show();
            if (json.exists_next){
                $('.supporting-comment-more').show();
                $('#support-comment-link').attr('href','/project/'+ code +'/communication/supporter/');
            }
            for (var i = 0; i<3; i++){
                if (!json.threads[i]) {
                    continue;
                }
                var supporting_comment_div = '<a class="supporting-comment-content" href="'
                                        + json.threads[i].thread_url_no_encode
                                        + '"><div class="supporting-comment-left"><img class="supporting-comment-icon" src="'
                                        + json.threads[i].user_image
                                        + '"></div><div class="supporting-comment-right"><p class="supporting-comment-name">'
                                        + json.threads[i].user_name
                                        + '</p><p class="supporting-comment-date">'
                                        + json.threads[i].fuzzy_time
                                        + '</p><p class="supporting-comment-message">'
                                        + json.threads[i].message
                                        + '</p></div></a>';
                $('#supporting-comment-wrapper').append(supporting_comment_div);
            }
        },
        function () {
            return;
        });
};
});
